var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "vendors-list"
  }, [_vm.scopeQs ? _c('div', [_c('Row', {
    attrs: {
      "type": "flex",
      "gutter": 24
    }
  }, [_c('Col', {
    staticClass: "small-widget-col",
    attrs: {
      "xs": 24,
      "sm": 24,
      "md": 6
    }
  }, [_c('ApiWidgetFactory', {
    attrs: {
      "namespace": "vendor",
      "description": {
        title: _vm.$t('All active vendors'),
        desc: _vm.$t('global.vendor.count_desc')
      },
      "params": {
        onlyDetected: true,
        limit: 1
      },
      "type": "ONE_VALUE"
    }
  })], 1), _c('Col', {
    staticClass: "small-widget-col",
    attrs: {
      "xs": 24,
      "sm": 24,
      "md": 6
    }
  }, [_c('ApiWidgetFactory', {
    attrs: {
      "namespace": "vendor",
      "description": {
        title: _vm.$t('Active vendors despite refusal'),
        desc: _vm.$t('')
      },
      "params": {
        onlyDetected: true,
        limit: 1,
        cmpScenarioOutcomes: ['REJECT']
      },
      "type": "ONE_VALUE"
    }
  })], 1), _c('Col', {
    staticClass: "small-widget-col",
    attrs: {
      "xs": 24,
      "sm": 24,
      "md": 6
    }
  }, [_c('ApiWidgetFactory', {
    attrs: {
      "namespace": "vendor",
      "description": {
        title: _vm.$t('Non declared vendors'),
        desc: _vm.$t('global.vendor.count_desc')
      },
      "params": {
        onlyDetected: true,
        limit: 1,
        CMP: 'NOT_FOUND'
      },
      "redirect": '/vendors/vendors-management',
      "type": "ONE_VALUE"
    }
  })], 1), _c('Col', {
    staticClass: "small-widget-col",
    attrs: {
      "xs": 24,
      "sm": 24,
      "md": 6
    }
  }, [_c('ApiWidgetFactory', {
    attrs: {
      "namespace": "vendor",
      "description": {
        title: _vm.$t('global.vendor.exempted_vendors'),
        desc: _vm.$t('global.vendor.exempted_vendors_desc')
      },
      "excluded-filters": ['isExempted'],
      "params": {
        onlyDetected: true,
        limit: 1,
        is_exempted: true
      },
      "type": "ONE_VALUE"
    }
  })], 1)], 1), _c('Row', {
    attrs: {
      "type": "flex",
      "gutter": 24
    }
  }, [_c('Col', {
    staticStyle: {
      "margin-bottom": "24px"
    },
    attrs: {
      "xs": 24,
      "sm": 24,
      "md": 24
    }
  }, [_c('WidgetTile', {
    attrs: {
      "api": "wvendors/vendors09",
      "is-actionable": true
    }
  })], 1)], 1), _c('Row', {
    staticStyle: {
      "margin-bottom": "24px"
    },
    attrs: {
      "type": "flex",
      "gutter": 24
    }
  }, [_c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "md": 12
    }
  }, [_c('WidgetTile', {
    staticClass: "vendors-cat",
    attrs: {
      "api": "wvendors/vendors22"
    }
  })], 1), _c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "md": 12
    }
  }, [_c('WidgetTile', {
    attrs: {
      "api": "wrequests/requests02"
    }
  })], 1)], 1)], 1) : _c('NoScope'), _c('WidgetFocus')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }