<template>
  <div class="vendors-list">
    <div v-if="scopeQs">
      <Row
        type="flex"
        :gutter="24"
      >
        <Col
          :xs="24"
          :sm="24"
          :md="6"
          class="small-widget-col"
        >
          <ApiWidgetFactory
            namespace="vendor"
            :description="{title: $t('All active vendors'), desc: $t('global.vendor.count_desc')}"
            :params="{onlyDetected: true, limit: 1}"
            type="ONE_VALUE"
          />
        </Col>
        <Col
          :xs="24"
          :sm="24"
          :md="6"
          class="small-widget-col"
        >
          <ApiWidgetFactory
            namespace="vendor"
            :description="{title: $t('Active vendors despite refusal'), desc: $t('')}"
            :params="{onlyDetected: true, limit: 1, cmpScenarioOutcomes: ['REJECT']}"
            type="ONE_VALUE"
          />
        </Col>
           
        <Col
          :xs="24"
          :sm="24"
          :md="6"
          class="small-widget-col"
        >
          <ApiWidgetFactory
            namespace="vendor"
            :description="{title: $t('Non declared vendors'), desc: $t('global.vendor.count_desc')}"
            :params="{onlyDetected: true, limit: 1, CMP:'NOT_FOUND'}"
            :redirect="'/vendors/vendors-management'"
            type="ONE_VALUE"
          />
        </Col>
        <Col
          :xs="24"
          :sm="24"
          :md="6"
          class="small-widget-col"
        >
          <ApiWidgetFactory
            namespace="vendor"
            :description="{title: $t('global.vendor.exempted_vendors'), desc: $t('global.vendor.exempted_vendors_desc')}"
            :excluded-filters="['isExempted']"
            :params="{onlyDetected: true, limit: 1, is_exempted: true}"
            type="ONE_VALUE"
          />
        </Col>
      </Row>
     
      <Row
        type="flex"
        :gutter="24"
      >
        <Col
          :xs="24"
          style="margin-bottom:24px"
          :sm="24"
          :md="24"
        >
          <WidgetTile
            api="wvendors/vendors09"
            :is-actionable="true"
          />
        </Col>
      </Row> 
      <Row
        type="flex"
        :gutter="24"
        style="margin-bottom:24px"
      >
        <Col
          :xs="24"
          :sm="24"
          :md="12"
        >
          <WidgetTile
            api="wvendors/vendors22"
            class="vendors-cat"
          />
        </Col>
        <Col
          :xs="24"
          :sm="24"
          :md="12"
        >
          <WidgetTile api="wrequests/requests02" />
        </Col>
      </Row>
    </div>
    <NoScope v-else />
    <WidgetFocus />
  </div>
</template>

<script>
import WidgetTile from '@/components/ui/WidgetTile'
import ApiWidgetFactory from '@/components/ui/ApiWidgetFactory'
import NoScope from '@/components/ui/NoScope'
import WidgetFocus from '@/components/ui/WidgetFocus'

import {mapGetters} from 'vuex'

export default {
  name: 'ActiveVendors',
  components: {
    WidgetTile,
    ApiWidgetFactory,
    NoScope,
    WidgetFocus
  },
  computed: {
    ...mapGetters({
      scopeQs: 'scope/qs'
    })
  }
}
</script>
<style lang="scss">
.vendors-list {
    .ivu-card {
    height:100%!important
  }
  .ivu-card-body {
    height:100%!important
  }
}

</style>